<template>
  <section>
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <h4 class="font-weight-bold">PO List</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 card-white">
        <!-- <vue-barcode  value="Hello, World!" :options="{ displayValue: false }"></vue-barcode> -->
        <table id="example" class="table table-striped table-bordered">
            <thead>
              <tr>
                    <!-- <th scope="col" style="width: 10px;"></th> -->
                    <th class='text-center' style="width: 100px;">เมนู</th>
                    <th class='text-center' style="width: 100px;">เลข PO</th>
                    <th class='text-center'>วันที่สร้าง</th>
                    <th class='text-center' style="width: 200px;">สาขา</th>
                    <th class='text-center'>บริษัท</th>
                    <th class='text-center' style="width: 200px;">หมายเหตุ</th>
                    <th class='text-center' style="width: 200px;">RQ</th>
                    <!-- <th class='text-center' style="width: 200px;">เลข PO-DS</th>
                    <th class='text-center' style="width: 200px;">หมายเหตุ DS</th> -->
                    <th class='text-center' style="width: 200px;">Invoice No</th>
                    <th class='text-center' style="width: 200px;">Ticket No DS</th>
                    <th class='text-center' style="width: 200px;">Control Number</th>
                    <!-- <th class='text-center' style="width: 200px;">Ticket No STS</th>
                    <th class='text-center' style="width: 200px;">CN</th> -->
                    <th class='text-center' style="width: 200px;">รอบรถ</th>
                    <th class='text-center' style="width: 200px;">QTREF</th>
                    <th class='text-center' style="width: 200px;">QTSTATUS</th>
                  </tr>
            </thead>
            <tbody>
                <tr  v-for="(e,index) in listData" :key="index">
                  <!-- <td></td> -->
                    <td>
                      <center>
                        <button @click="getListOrder(e.PO_NUM)" :disabled="e.Status != 'A' || e.QTSTATUS == 'C' || !$store.getters['service/getDSCUSTID']" type="button" :class="`btn-visible-${$store.getters['customer/getQTBU']}`"  :style="`${e.Status != 'A' || e.QTSTATUS == 'C'  || !$store.getters['service/getDSCUSTID'] ?'background-color: lightgray !important;cursor: not-allowed;':''}`">{{e.Status == 'A'  && e.QTSTATUS != 'C'? 'เลือก' : '-'}}</button>
                      </center>
                    </td>
                    <td><a @click="showListProduct(e.PO_NUM)">{{e.PO_NUM}}</a></td>
                    <td>{{e.CreateDate}}</td>
                    <td>{{e.STC}}</td>
                    <td>{{e.Company}}</td>
                    <td>{{e.Remark}}</td>
                    <td>{{e.requestRQ}}</td>
                    <td>{{e.invoice_no == '' ? '' : (e.invoice_no.includes('/') ? e.invoice_no.split('/')[1] : e.invoice_no)}}</td>
                    <td>{{e.ticketDS}}</td>
                    <td>{{e.controlnumber?e.controlnumber:''}}</td>
                    <td>{{(e.dlvremark != '' || !(e.ticketDS != '' && e.dlvremark == ''))  ? e.dlvremark  : ''}}</td>
                    <td>{{e.QTREF}}</td>
                    <td>{{e.QTSTATUS}}</td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>

    <div id="ModalDetailPO" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <span>เลข PO : {{ PO_NUMdet }}</span>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title"></h4>
                </div>
                <div class="modal-body">
                    <div class="row" id="POShowDetail" v-for="(e,index) in detData" :key="index">
                        <div class='flex-con'>
                            <div class="flex-img">
                                <ImageBySKU style="width: 100%;" :sku="e.SKU" /> 
                            </div>
                            <div class="flex-detail">
                                <h3>{{e.Description}}</h3>
                                <h4>SKU: {{ e.SKU }}  จำนวน: {{ e.SumPO_Qty }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

  </section>
</template>


<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import ImageBySKU from '@/components/ImageBySKU.vue'
  import 'jquery/dist/jquery.min.js';
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery';
  export default {
    name: 'POList',
    components: {
      ImageBySKU
    },
    props: [
      "whereBy"
    ],
    data: function () {
      return {
        listData: [],
        isLoading: true,
        startCount: '0',
        wantShowCount: 4,
        isZeroRow: false,
        MaxCount: null,
        searchTxt: '',
        nowPage: 1,
        firstPage:1,
        lastPage:1,
        maxPage: 0,
        checkCard:false,
        isLoading:false,
        customTable: {
          deferRender: true,
          scrollX: true,
          scrollY: '50vh',
          scrollCollapse: true,
          scroller: {
            loadingIndicator: true
          },
          "pageLength": 50,
          "order": [
            [3, 'desc']
          ],
          "columnDefs": [{
            "targets": [1, 2, 6],
            "width": "100"
          }, {
            "targets": [ 3, 4, 7, 8, 9, 10, 11, 12],
            "width": "200"
          }]
        },
        detData:[],
        PO_NUMdet:'',

      }
    },
    mounted() {
      this.getDatatable()
    },
    methods: {
      async setDataTable(){
        $('#example').DataTable(this.customTable);
        // $('#example').DataTable();
      },
      async getDatatable(){
        try {
            if(!this.getQTSTORE){
              throw new Error('กรุณาเลือกสาขาก่อนครับ')
            }
            // if(!this.$store.getters['service/getDSCUSTID']){
            //   throw new Error('ไม่พบลูกค้า')
            // }
            let data={
              STCODE: this.getQTSTORE,
              CUSTNO: this.$store.getters['service/getDSCUSTID']
            }
            let getAPI = await serviceAPI.call_API('post',`history/getPORequestHistory`,data,'auth');
            this.listData = getAPI.data.dbitems;

            
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },
      
      async getListOrder(PO_NUM){
        try {
            if(!this.getQTSTORE){
              throw new Error('กรุณาเลือกสาขาก่อนครับ')
            }
            if(!this.getQTCUSTREF){
              throw new Error('กรุณาเลือกตะกร้าสินค้าก่อนครับ')
            }
            if(!this.$store.getters['service/getDSMMSSTORE']){
              throw new Error('ไม่พบสาขาเครดิต')
            }
            let data={
              STCODE: this.getQTSTORE,
              POLIST: PO_NUM
            }
            let getAPI = await serviceAPI.call_API('post',`history/getPORequestHistory_ListOrder`,data,'auth');
            console.log(getAPI);
            let listOrder = getAPI.data.dbitems

            //clear cart sku
            let dataClearCart={
              REF:this.getQTCUSTREF
            }
            let getAPIClearCart = await serviceAPI.call_API('post',`history/clearCartItems`,dataClearCart,'auth');
            
            //add po sku
            for (let e of listOrder) {
              let dataADDPO = {
                "REF":this.getQTCUSTREF, 
                "SKU":e.SKU, 
                "STCODE":this.getQTSTORE, 
                "QNT":e.SumPO_Qty?parseInt(e.SumPO_Qty):1
              }
              let getAPIADDPO = await serviceAPI.call_API('post', `cart/addtocart`, dataADDPO,'auth');
              let getDataADDPO = getAPIADDPO.data.dbitems[0]
              this.$store.dispatch('customer/setQTSHOPITEM',getDataADDPO.SUMQNT)
            }

            //บันทึกPO
            let dataPO={
              REF:this.getQTCUSTREF ,
              REMARK2:PO_NUM ,
            }
            await serviceAPI.call_API('post',`at1Order/updateCustomerCar`,dataPO,'auth');
            this.$store.dispatch('customer/setQTPONUM', PO_NUM)

            serviceMain.showSuccessAlertWithTime(this,`เพิ่มสินค้า PO ${PO_NUM} สำเร็จ`,1000)
            // this.hideModal()
            this.$router.push({  name: 'Cart' });
            // this.$store.dispatch("service/setReloadCart")

            
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },

      async showListProduct(PO_NUM){
        try {
          this.PO_NUMdet = ''
          this.detData = []
            let data={
              STCODE: this.getQTSTORE,
              POLIST: PO_NUM
            }
            let getAPI = await serviceAPI.call_API('post',`history/getPORequestHistory_ListOrder`,data,'auth');
            console.log(getAPI);
            this.detData = getAPI.data.dbitems
            this.PO_NUMdet = PO_NUM
            // $('#ModalDetailPO').modal('show')
            window.$('#ModalDetailPO').modal('show')
        } catch (error) {
          serviceMain.showErrorAlert(this,error)
        }
      },

    },
    computed: {
      getQTUSERID: function () {
        return this.$store.getters['auth/getQTUSERID']
      },
      getQTCUSTINFO: function () {
        return this.$store.getters['customer/getQTCUSTINFO']
      },
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getQTCUSTREF: function () {
        return this.$store.getters['customer/getQTCUSTREF']
      },
      getQTSTORE: function () {
        return this.$store.getters['service/getQTDFSTORE']
      },
    },
    watch: {
      listData: function() {
        this.$nextTick(() => {
          this.setDataTable();
        })
      }
    }
  }
</script>

<style scoped lang="scss">
button:disabled,
button[disabled]{
  pointer-events: none;
  cursor: not-allowed;
}
.flex-con{
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}
.flex-img{
    max-width: 150px;
    min-width: 100px;
}
.flex-detail{
    flex-grow: 1;

}
</style>